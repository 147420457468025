import * as React from "react";
import { useDispatch, useSelector } from "react-redux";

import { setEndDateThunk } from "./thunks/viz";

import { DatePicker } from "antd";
import dayjs from "dayjs";

export function EndDateDatePicker({ style }) {
  const dispatch = useDispatch();
  const endDate = useSelector((state) => state.form.endDate);

  return (
    <DatePicker
      allowClear={false}
      inputReadOnly={true}
      value={endDate ? dayjs(endDate) : dayjs()}
      onChange={(newDate) => {
        dispatch(setEndDateThunk(newDate.format("YYYY-MM-DD")));
      }}
      style={style}
    />
  );
}
