import dayjs from "dayjs";

import { formSlice, vizSlice } from "../store";

const a = vizSlice.actions;
const { setEndDate, setFieldId, setGroup } = formSlice.actions;

async function dataFetch(route, group, fieldId, date = undefined) {
  let url = `/data/v1/${route}/${group}/${fieldId}`;
  if (date) {
    url += `?date=${date}`;
  }
  const response = await fetch(url);
  if (response.ok) {
    return await response.json();
  }
  return null;
}

export const setEndDateThunk = (date) => async (dispatch, getState) => {
  dispatch(setEndDate(date));
  const {
    form: { group, fieldId },
  } = getState();
  dispatch(a.setRiskMaps(""));
  try {
    const data = await dataFetch("risk-maps", group, fieldId, date);
    dispatch(a.setRiskMaps(data));
  } catch {
    dispatch(a.setRiskMaps(null));
  }
};

export const vizThunk = (group, fieldId) => async (dispatch, getState) => {
  dispatch(setGroup(group));
  dispatch(setFieldId(fieldId));
  dispatch(a.setPrecipitation(""));
  dispatch(a.setPlantingWindow(""));
  dispatch(a.setRiskMaps(""));
  dispatch(a.setSaturationRisk(""));
  dispatch(a.setSufficiencyRisk(""));
  dispatch(a.setSeedZoneMoisture(""));
  dispatch(a.setWaterAvailable(""));
  dispatch(a.setFieldLevelMoisture(""));

  const {
    form: { endDate },
  } = getState();

  async function fetchDataAndDispatch(dataType, actionCreator) {
    try {
      const date = endDate ? endDate : undefined;
      const data = await dataFetch(dataType, group, fieldId, date);
      dispatch(actionCreator(data));
      if (data.name === "risk-maps") {
        const date = dayjs(data.data.time);
        dispatch(setEndDate(date.format("YYYY-MM-DD")));
      }
    } catch {
      dispatch(actionCreator(null));
    }
  }

  await fetchDataAndDispatch("precipitation", a.setPrecipitation);
  await fetchDataAndDispatch("planting-window", a.setPlantingWindow);
  await fetchDataAndDispatch("risk-maps", a.setRiskMaps);
  await fetchDataAndDispatch("saturation-risk", a.setSaturationRisk);
  await fetchDataAndDispatch("sufficiency-risk", a.setSufficiencyRisk);
  await fetchDataAndDispatch("seed-zone-moisture", a.setSeedZoneMoisture);
  await fetchDataAndDispatch("water-available", a.setWaterAvailable);
  await fetchDataAndDispatch("field-level-moisture", a.setFieldLevelMoisture);
};
