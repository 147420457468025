import { Col, Radio, Row } from "antd";
import Plot from "react-plotly.js";
import { useDispatch, useSelector } from "react-redux";
import { useMediaQuery } from "@uidotdev/usehooks";
import { formSlice } from "../store";

import * as utils from "./utils";

const colorscale = [
  [0, "rgb(247, 251, 255)"],
  [0.5, "rgb(109, 174, 213)"],
  [1, "rgb(8, 48, 107)"],
];

const WaterAvailableMaps = ({ data }) => {
  let {
    times,
    data: { aoi_lon, aoi_lat, lon, lat, past_1day, future_7day },
  } = data;
  const isImperial = useSelector((state) => state.form.units === "imperial");
  const waterAvailable3D = useSelector((state) => state.form.waterAvailable3D);
  const mobile = useMediaQuery("only screen and (max-width : 925px)");
  const dispatch = useDispatch();
  const { setWaterAvailable3D } = formSlice.actions;

  return (
    <>
      <Row justify="center" gutter={10}>
        <Col>
          <p style={{ font: "Arial", fontSize: mobile ? 8 : 17 }}>
            Soil Water Content Variability
          </p>
        </Col>
        <Col align="top">
          <Radio.Group
            onChange={() => dispatch(setWaterAvailable3D())}
            size="small"
            value={waterAvailable3D ? "3D" : "2D"}
          >
            <Radio.Button value="2D">2D</Radio.Button>
            <Radio.Button value="3D">3D</Radio.Button>
          </Radio.Group>
        </Col>
      </Row>
      <Row>
        <Col xs={12}>
          <Row justify="center">
            <Plot
              divId="water-available-surface-p1day"
              data={[
                {
                  x: lon,
                  y: lat,
                  z: past_1day.map((row) =>
                    row.map((z) => (isImperial ? utils.toInches(z) : z))
                  ),
                  type: "heatmap",
                  colorscale,
                  name: "Water Available",
                  hovertemplate: "%{z:.0f}%<extra></extra>",
                  showscale: !mobile,
                  colorbar: {
                    tickformat: ".1f",
                    title: `Water Available (${isImperial ? "in" : "mm"})`,
                    titleside: "right",
                  },
                },
                {
                  x: aoi_lon,
                  y: aoi_lat,
                  mode: "lines",
                  line: {
                    color: utils.colors[1],
                    width: 3,
                  },
                  name: "AOI",
                  hoverinfo: "none",
                },
              ]}
              {...utils.plotProps2d(`Current: ${times[0]}`, mobile)}
            />
          </Row>
        </Col>
        <Col xs={12}>
          <Row justify="center">
            <Plot
              divId="water-available-surface-f7day"
              data={[
                {
                  x: lon,
                  y: lat,
                  z: future_7day.map((row) =>
                    row.map((z) => (isImperial ? utils.toInches(z) : z))
                  ),
                  type: "heatmap",
                  colorscale,
                  name: "Water Available",
                  hovertemplate: "%{z:.0f}%<extra></extra>",
                  showscale: !mobile,
                  colorbar: {
                    tickformat: ".1f",
                    title: `Water Available (${isImperial ? "in" : "mm"})`,
                    titleside: "right",
                  },
                },
                {
                  x: aoi_lon,
                  y: aoi_lat,
                  mode: "lines",
                  line: {
                    color: utils.colors[1],
                    width: 3,
                  },
                  name: "AOI",
                  hoverinfo: "none",
                },
              ]}
              {...utils.plotProps2d(`Forecast: ${times[1]}`, mobile)}
            />
          </Row>
        </Col>
      </Row>
    </>
  );
};

export default WaterAvailableMaps;
